const SettingsIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.14401C27.13 5.52161 24.7739 4.60121 22.1738 4.25691C19.6907 3.92781 18 4.00881 18 4.00881C18 4.00881 16.309 3.92781 13.8258 4.25691C11.2258 4.60121 8.86979 5.52161 7.14429 7.14401C5.52149 8.86981 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.86981 28.856 7.14401Z"
      fill="#5e5e92"
    />
    <path
      d="M21.5 16.4C22.2895 16.4 23 17.0519 23 17.9484C23 18.8448 22.3684 19.4968 21.5 19.4968C20.7105 19.4968 20 18.8448 20 17.9484C20 17.0519 20.6316 16.4 21.5 16.4Z"
      fill="white"
    />
    <path
      d="M14.5 21.8C15.2895 21.8 16 22.4519 16 23.3484C16 24.2448 15.3684 24.8968 14.5 24.8968C13.7105 24.8968 13 24.2448 13 23.3484C13 22.4519 13.6316 21.8 14.5 21.8Z"
      fill="white"
    />
    <path
      d="M24.3226 22.7586C24.6965 22.7586 25 23.0367 25 23.3793C25 23.7228 24.6965 24 24.3226 24H11.6774C11.3035 24 11 23.7228 11 23.3793C11 23.0367 11.3035 22.7586 11.6774 22.7586H24.3226ZM24.3226 17.3793C24.6965 17.3793 25 17.6574 25 18C25 18.3434 24.6965 18.6207 24.3226 18.6207H11.6774C11.3035 18.6207 11 18.3434 11 18C11 17.6574 11.3035 17.3793 11.6774 17.3793H24.3226ZM24.3226 12C24.6965 12 25 12.2781 25 12.6207C25 12.9641 24.6965 13.2414 24.3226 13.2414H11.6774C11.3035 13.2414 11 12.9641 11 12.6207C11 12.2781 11.3035 12 11.6774 12H24.3226Z"
      fill="white"
    />
    <path
      d="M14.5 11C15.2895 11 16 11.6519 16 12.5484C16 13.4448 15.3684 14.0968 14.5 14.0968C13.7105 14.0968 13 13.4448 13 12.5484C13 11.6519 13.6316 11 14.5 11Z"
      fill="white"
    />
  </svg>
)

export default SettingsIcon
