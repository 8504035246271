const ScenariosIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.14401C27.13 5.52161 24.7739 4.60121 22.1738 4.25691C19.6907 3.92781 18 4.00881 18 4.00881C18 4.00881 16.309 3.92781 13.8258 4.25691C11.2258 4.60121 8.86979 5.52161 7.14429 7.14401C5.52149 8.86981 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.86981 28.856 7.14401Z"
      fill="#5d4fc8"
    />
    <path
      d="M18.23 10L20.7793 12.1593L18.23 14.3967V12.9163C18.2115 12.9172 18.1938 12.9217 18.1752 12.9217C15.1073 12.9217 12.6129 15.2875 12.6129 18.1962C12.6129 21.1041 15.1073 23.4707 18.1752 23.4707C20.543 23.4707 22.6559 22.047 23.4318 19.9274C23.5778 19.5289 24.0375 19.3172 24.4584 19.4564C24.8786 19.5947 25.1012 20.0306 24.9552 20.429C23.9536 23.1633 21.2285 25 18.1752 25C14.2186 25 11 21.9476 11 18.1962C11 14.4441 14.2186 11.3925 18.1752 11.3925C18.1938 11.3925 18.2115 11.3962 18.23 11.3978V10ZM23.0036 14.5073C23.3173 14.2076 23.8286 14.206 24.1439 14.5034C24.4601 14.8009 24.4625 15.2849 24.148 15.5846L18.3486 21.123L14.73 17.8097C14.4098 17.5161 14.401 17.0321 14.7106 16.7285C15.0195 16.425 15.5308 16.4166 15.8501 16.7102L18.3244 18.975L23.0036 14.5073Z"
      fill="white"
    />
  </svg>
)

export default ScenariosIcon
