const SoundIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.14401C27.13 5.52161 24.7739 4.60121 22.1738 4.25691C19.6907 3.92781 18 4.00881 18 4.00881C18 4.00881 16.309 3.92781 13.8258 4.25691C11.2258 4.60121 8.86979 5.52161 7.14429 7.14401C5.52149 8.86981 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.86981 28.856 7.14401Z"
      fill="#6067d1"
    />
    <path
      d="M17.7008 12.2531C18.2431 11.7914 18.6864 11.9797 18.6864 12.6687V23.3309C18.6864 24.0208 18.2431 24.2081 17.7008 23.7474L13.6565 20.5161V20.4843C13.5709 20.4977 13.4843 20.5069 13.3952 20.5069H11.5456C10.6951 20.5069 10 19.8479 10 19.0434V16.9528C10 16.1475 10.6951 15.4893 11.5456 15.4893H13.3952C13.4843 15.4893 13.5709 15.4986 13.6565 15.512V15.4835L17.7008 12.2531ZM22.4856 12.556C22.7347 12.3018 23.1543 12.2875 23.4219 12.5225C25.0849 13.9834 26 15.9285 26 18.0008C26 20.0722 25.0841 22.0173 23.4219 23.4774C23.2937 23.5895 23.133 23.6447 22.9714 23.6447C22.7939 23.6447 22.6164 23.5778 22.4856 23.4448C22.2366 23.1905 22.2534 22.7934 22.521 22.5576C23.9103 21.3383 24.6752 19.7193 24.6752 18.0008C24.6752 16.2814 23.9103 14.6634 22.521 13.4424C22.2534 13.2066 22.2366 12.8102 22.4856 12.556ZM20.6036 14.955C20.8554 14.7051 21.274 14.6933 21.5407 14.9316C22.4522 15.7495 22.9547 16.8383 22.9556 17.9999C22.9556 19.1614 22.4522 20.2511 21.5407 21.0689C21.4118 21.1843 21.2483 21.2403 21.085 21.2403C20.9092 21.2403 20.7343 21.1751 20.6036 21.0455C20.3519 20.7937 20.3643 20.3957 20.6301 20.1582C21.2748 19.5795 21.6308 18.8135 21.6308 17.9999C21.6299 17.187 21.2748 16.4201 20.6301 15.8424C20.3643 15.604 20.3519 15.2068 20.6036 14.955Z"
      fill="white"
    />
  </svg>
)

export default SoundIcon
