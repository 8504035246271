const WiFiIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.144C27.13 5.5216 24.7739 4.6012 22.1738 4.2569C19.6907 3.9278 18 4.0088 18 4.0088C18 4.0088 16.309 3.9278 13.8258 4.2569C11.2258 4.6012 8.86979 5.5216 7.14429 7.144C5.52149 8.8698 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.8698 28.856 7.144Z"
      fill="#336ee6"
    />
    <path
      d="M18.0001 22.8207C18.5759 22.8207 19.0409 23.3081 19.0409 23.9103C19.0409 24.5126 18.5759 25 18.0001 25C17.4245 25 16.9586 24.5126 16.9586 23.9103C16.9586 23.3081 17.4245 22.8207 18.0001 22.8207ZM14.5406 20.3988C16.4481 18.4024 19.5515 18.4024 21.4598 20.3988C21.7214 20.6723 21.7214 21.1159 21.4598 21.3894C21.3287 21.5258 21.1573 21.5939 20.9868 21.5939C20.8146 21.5939 20.644 21.5258 20.5129 21.3894C19.1276 19.9394 16.873 19.9394 15.4877 21.3894C15.2261 21.663 14.8022 21.663 14.5406 21.3894C14.2792 21.1159 14.2792 20.6723 14.5406 20.3988ZM18.0001 14.8301C20.3458 14.8301 22.5514 15.7853 24.2098 17.5212C24.4712 17.7947 24.4712 18.2383 24.2098 18.5109C24.0795 18.6481 23.9081 18.7163 23.7367 18.7163C23.5653 18.7163 23.394 18.6481 23.2627 18.5109C20.3619 15.4753 15.6384 15.4753 12.7367 18.5109C12.4761 18.7844 12.0512 18.7844 11.7898 18.5109C11.5282 18.2383 11.5282 17.7947 11.7898 17.5212C13.448 15.7853 15.6546 14.8301 18.0001 14.8301ZM9.19619 14.8082C14.0508 9.73059 21.95 9.73059 26.8038 14.8082C27.0654 15.0818 27.0654 15.5253 26.8038 15.7989C26.6735 15.9352 26.5013 16.0034 26.3308 16.0034C26.1585 16.0034 25.9872 15.9352 25.8569 15.7989C21.5243 11.2665 14.4748 11.2665 10.1431 15.7989C9.88168 16.0725 9.45765 16.0725 9.19619 15.7989C8.9346 15.5253 8.9346 15.0818 9.19619 14.8082Z"
      fill="white"
    />
  </svg>
)

export default WiFiIcon
