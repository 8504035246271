const ParentsControlIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.14401C27.13 5.52161 24.7739 4.60121 22.1738 4.25691C19.6907 3.92781 18 4.00881 18 4.00881C18 4.00881 16.309 3.92781 13.8258 4.25691C11.2258 4.60121 8.86979 5.52161 7.14429 7.14401C5.52149 8.86981 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.86981 28.856 7.14401Z"
      fill="#3d9d32"
    />
    <path
      d="M19.8277 14.5595C21.0607 14.5595 22.0597 15.6345 22.0597 16.9605C22.0597 17.4225 21.9387 17.8515 21.7287 18.2175C21.7287 18.2175 21.5927 18.4265 21.5437 18.4965C21.0137 19.2485 19.5037 20.6015 18.6357 21.3505C18.2697 21.6655 17.7317 21.6655 17.3667 21.3505C16.4987 20.6015 14.9887 19.2485 14.4587 18.4965C14.4087 18.4265 14.2717 18.2175 14.2717 18.2175C14.0627 17.8515 13.9417 17.4225 13.9417 16.9605C13.9417 15.6345 14.9407 14.5595 16.1737 14.5595C16.9297 14.5595 17.5977 14.9625 18.0017 15.5805C18.4047 14.9625 19.0717 14.5595 19.8277 14.5595Z"
      fill="white"
    />
    <path
      d="M25.058 21.7128C23.71 24.2648 21.055 25.9998 18 25.9998C13.582 25.9998 10 22.3688 10 17.8908C10 14.0628 12.617 10.8548 16.134 10.0028M19.8578 10C23.3798 10.849 26.0008 14.06 26.0008 17.891C26.0008 17.99 25.9988 18.089 25.9958 18.188"
      stroke="white"
      strokeWidth="1.5"
    />
  </svg>
)

export default ParentsControlIcon
