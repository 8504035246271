const InfoIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.14401C27.13 5.52161 24.7739 4.60121 22.1738 4.25691C19.6907 3.92781 18 4.00881 18 4.00881C18 4.00881 16.309 3.92781 13.8258 4.25691C11.2258 4.60121 8.86979 5.52161 7.14429 7.14401C5.52149 8.86981 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.86981 28.856 7.14401Z"
      fill="#5e5e92"
    />
    <path
      d="M19.0488 22.2517C19.0488 22.694 18.6486 23.0426 18.1901 22.9547C17.85 22.8888 17.616 22.5717 17.616 22.225V17.8682H16.7401C16.3933 17.8682 16.0752 17.6341 16.0103 17.2931C15.9224 16.8356 16.271 16.4353 16.7133 16.4353H18.5712C18.8349 16.4353 19.0488 16.6484 19.0488 16.913V22.2517ZM18 12.9784C18.5282 12.9784 18.9552 13.4063 18.9552 13.9336C18.9552 14.4609 18.5282 14.8888 18 14.8888C17.4727 14.8888 17.0448 14.4609 17.0448 13.9336C17.0448 13.4063 17.4727 12.9784 18 12.9784ZM18 10C13.5888 10 10 13.5888 10 18C10 22.4112 13.5888 26 18 26C22.4122 26 26 22.4112 26 18C26 13.5888 22.4122 10 18 10Z"
      fill="white"
    />
  </svg>
)

export default InfoIcon
