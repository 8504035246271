const SimIcon = () => (
  <svg
    version="1.1"
    width="20px"
    height="20px"
    viewBox="0 0 400 400"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(1.005168, 0, 0, 1.023159, 4.774346, 39.566433)">
      <path
        d="M 311.147 122.618 L 310.979 121.984 C 310.847 120.888 310.588 119.826 310.222 118.745 L 309.856 117.661 L 309.592 117.102 C 309.009 115.847 308.342 114.733 307.568 113.725 L 307.364 113.347 L 199.017 4.999 C 197.618 3.597 195.978 2.477 193.925 1.525 L 192.489 0.997 C 191.427 0.634 190.343 0.375 189.204 0.222 L 188.376 0 L 82.206 0 C 50.993 0 39.599 11.376 39.599 42.583 L 39.599 308.223 C 39.599 339.418 50.994 350.8 82.206 350.8 L 273.618 350.8 C 304.819 350.8 311.201 344.418 311.201 313.223 L 311.201 124.216 L 311.147 122.618 Z"
        fill="#fbbf43"
      />
    </g>
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24px"
      stroke="#fbbf43"
      d="M 346.004 260.79 L 347.422 144.522 L 213.83 11.509 L 130.474 11.509"
    />
  </svg>
)

export default SimIcon
