const AdditionalFunctionsIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.144C27.13 5.5216 24.7739 4.6012 22.1738 4.2569C19.6907 3.9278 18 4.0088 18 4.0088C18 4.0088 16.309 3.9278 13.8258 4.2569C11.2258 4.6012 8.86979 5.5216 7.14429 7.144C5.52149 8.8698 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.8698 28.856 7.144Z"
      fill="#cd9a3f"
    />
    <path
      d="M20.9844 18.6857H18.7158V20.8572C18.7158 21.2365 18.3959 21.5428 17.9995 21.5428C17.6041 21.5428 17.2832 21.2365 17.2832 20.8572V18.6857H15.0147C14.6193 18.6857 14.2983 18.3795 14.2983 18C14.2983 17.6215 14.6193 17.3143 15.0147 17.3143H17.2832V15.1428C17.2832 14.7643 17.6041 14.4572 17.9995 14.4572C18.3959 14.4572 18.7158 14.7643 18.7158 15.1428V17.3143H20.9844C21.3807 17.3143 21.7008 17.6215 21.7008 18C21.7008 18.3795 21.3807 18.6857 20.9844 18.6857ZM24.1937 17.8226C24.1803 17.6307 24.3217 17.3289 24.508 17.1525L25.8232 15.9109C26.0104 15.7345 26.0563 15.4117 25.9254 15.1941L24.4918 12.8078C24.3609 12.5893 24.0466 12.4668 23.7935 12.5336L22.0188 13.0072C21.7667 13.0738 21.4209 13.0446 21.2509 12.9395C21.0818 12.8352 20.5546 12.401 20.4896 12.1578L20.0283 10.4426C19.9633 10.1994 19.694 10 19.4323 10H16.5667C16.305 10 16.0357 10.1994 15.9708 10.4426L15.5104 12.1578C15.4445 12.401 15.2448 12.6743 15.0653 12.764C14.8858 12.8545 14.2333 13.0738 13.9802 13.0072L12.2065 12.5336C11.9534 12.4668 11.6391 12.5893 11.5082 12.8078L10.0746 15.1941C9.94369 15.4117 9.98962 15.7345 10.1768 15.9109L11.491 17.1515C11.6783 17.328 11.8197 17.6297 11.8063 17.8226V18V18.1774C11.8197 18.3703 11.6783 18.672 11.491 18.8485L10.1768 20.09C9.98962 20.2665 9.94369 20.5893 10.0746 20.8078L11.5082 23.1941C11.6391 23.4117 11.9534 23.5351 12.2065 23.4664L13.9802 22.9938C14.2333 22.9262 14.5791 22.9563 14.7482 23.0605C14.9182 23.1648 15.4445 23.599 15.5104 23.8422L15.9708 25.5574C16.0367 25.8006 16.305 26 16.5677 26H19.4323C19.694 26 19.9633 25.8006 20.0283 25.5574L20.4896 23.8422C20.5546 23.599 20.7552 23.3266 20.9347 23.237C21.1134 23.1465 21.7667 22.9262 22.0188 22.9938L23.7935 23.4664C24.0466 23.5351 24.3609 23.4117 24.4918 23.1941L25.9254 20.8078C26.0563 20.5893 26.0104 20.2665 25.8232 20.09L24.508 18.8485C24.3217 18.672 24.1803 18.3703 24.1937 18.1774V18V17.8226Z"
      fill="white"
    />
  </svg>
)

export default AdditionalFunctionsIcon
