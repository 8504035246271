const HotSpotIcon = () => (
  <svg
    height="22px"
    width="22px"
    version="1.1"
    viewBox="0 0 26.4 26.4"
    xmlns="http://www.w3.org/2000/svg"
    fill="#72cb3b"
  >
    <g
      style={{ transformOrigin: '10.3829px 10.3832px' }}
      transform="matrix(0, 1, -1, 0, 8.210904, 2.81684)"
    >
      <g>
        <circle cx="10.432" cy="15.771" r="2.503" />
        <path
          d="M16.23,9.926c-0.036-0.037-0.074-0.07-0.111-0.104L16.01,9.719&#10;&#9;&#9;&#9;c-0.018-0.019-0.038-0.033-0.061-0.051c-3.137-2.85-7.938-2.737-10.932,0.257l-0.351,0.352L4.18,10.763&#10;&#9;&#9;&#9;c-0.592,0.592-0.589,1.557,0.004,2.15c0.594,0.595,1.559,0.596,2.15,0.004l0.838-0.838c1.904-1.903,5.001-1.903,6.904-0.001&#10;&#9;&#9;&#9;l0.605,0.586c0.586,0.586,1.542,0.585,2.131-0.005c0.285-0.284,0.442-0.663,0.443-1.066c0.001-0.401-0.154-0.78-0.439-1.063&#10;&#9;&#9;&#9;L16.23,9.926z"
        />
        <path
          d="M20.363,6.927l-0.504-0.504c-0.002-0.002-0.003-0.005-0.007-0.006l-0.398-0.4&#10;&#9;&#9;&#9;c-0.07-0.069-0.146-0.129-0.226-0.181c-5.113-4.489-12.89-4.351-17.84,0.411C1.364,6.269,1.337,6.284,1.313,6.308l-0.91,0.911&#10;&#9;&#9;&#9;c-0.54,0.539-0.537,1.416,0.006,1.959C0.951,9.72,1.828,9.724,2.368,9.183l0.91-0.909c0.006-0.008,0.011-0.015,0.017-0.02&#10;&#9;&#9;&#9;c4.003-3.867,10.353-3.891,14.388-0.079l0.716,0.717c0.539,0.539,1.418,0.537,1.96-0.005C20.9,8.344,20.902,7.466,20.363,6.927z"
        />
      </g>
    </g>
    <g
      style={{ transformOrigin: '10.383px 10.383px' }}
      transform="matrix(0, 1, 1, 0, -2.56932, 2.817065)"
    >
      <g>
        <path
          d="M16.23,9.926c-0.036-0.037-0.074-0.07-0.111-0.104L16.01,9.719&#10;&#9;&#9;&#9;c-0.018-0.019-0.038-0.033-0.061-0.051c-3.137-2.85-7.938-2.737-10.932,0.257l-0.351,0.352L4.18,10.763&#10;&#9;&#9;&#9;c-0.592,0.592-0.589,1.557,0.004,2.15c0.594,0.595,1.559,0.596,2.15,0.004l0.838-0.838c1.904-1.903,5.001-1.903,6.904-0.001&#10;&#9;&#9;&#9;l0.605,0.586c0.586,0.586,1.542,0.585,2.131-0.005c0.285-0.284,0.442-0.663,0.443-1.066c0.001-0.401-0.154-0.78-0.439-1.063&#10;&#9;&#9;&#9;L16.23,9.926z"
        />
        <path
          d="M20.363,6.927l-0.504-0.504c-0.002-0.002-0.003-0.005-0.007-0.006l-0.398-0.4&#10;&#9;&#9;&#9;c-0.07-0.069-0.146-0.129-0.226-0.181c-5.113-4.489-12.89-4.351-17.84,0.411C1.364,6.269,1.337,6.284,1.313,6.308l-0.91,0.911&#10;&#9;&#9;&#9;c-0.54,0.539-0.537,1.416,0.006,1.959C0.951,9.72,1.828,9.724,2.368,9.183l0.91-0.909c0.006-0.008,0.011-0.015,0.017-0.02&#10;&#9;&#9;&#9;c4.003-3.867,10.353-3.891,14.388-0.079l0.716,0.717c0.539,0.539,1.418,0.537,1.96-0.005C20.9,8.344,20.902,7.466,20.363,6.927z"
          transform="matrix(1, 0, 0, 1, 4.47035e-7, -2.38419e-7)"
        />
      </g>
    </g>
  </svg>
)

export default HotSpotIcon
