const CleanUpIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.14401C27.13 5.52161 24.7739 4.60121 22.1738 4.25691C19.6907 3.92781 18 4.00881 18 4.00881C18 4.00881 16.309 3.92781 13.8258 4.25691C11.2258 4.60121 8.86979 5.52161 7.14429 7.14401C5.52149 8.86981 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.86981 28.856 7.14401Z"
      fill="#0f9c8c"
    />
    <path
      d="M25.058 21.7128C23.71 24.2648 21.055 25.9998 18 25.9998C13.582 25.9998 10 22.3688 10 17.8908C10 14.0628 12.617 10.8548 16.134 10.0028M19.8578 10C23.3798 10.849 26.0008 14.06 26.0008 17.891C26.0008 17.99 25.9988 18.089 25.9958 18.188"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M16.4348 14.3333C16.9354 14.3333 17.3466 14.7152 17.3932 15.2036L17.3976 15.2964V17.3944C17.3976 17.6705 17.1738 17.8944 16.8976 17.8944C16.6445 17.8944 16.4353 17.7063 16.4022 17.4622L16.3976 17.3944L16.397 15.8512C15.7384 16.3682 15.3328 17.1658 15.3328 18.0367C15.3328 19.571 16.5809 20.8154 18.1208 20.8154C19.6612 20.8154 20.9089 19.5713 20.9089 18.0367C20.9089 16.7557 20.0334 15.6503 18.8044 15.3408C18.5366 15.2734 18.3742 15.0016 18.4416 14.7338C18.5091 14.4661 18.7808 14.3036 19.0486 14.3711C20.7189 14.7916 21.9089 16.2942 21.9089 18.0367C21.9089 20.1242 20.2128 21.8154 18.1208 21.8154C16.0293 21.8154 14.3328 20.124 14.3328 18.0367C14.3328 16.9934 14.7606 16.0276 15.474 15.332L14.3334 15.3333C14.0802 15.3333 13.871 15.1452 13.8379 14.9012L13.8334 14.8333C13.8334 14.5802 14.0215 14.371 14.2655 14.3378L14.3334 14.3333H16.4348Z"
      fill="white"
    />
  </svg>
)

export default CleanUpIcon
