const AppsIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.14401C27.13 5.52161 24.7739 4.60121 22.1738 4.25691C19.6907 3.92781 18 4.00881 18 4.00881C18 4.00881 16.309 3.92781 13.8258 4.25691C11.2258 4.60121 8.86979 5.52161 7.14429 7.14401C5.52149 8.86981 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.86981 28.856 7.14401Z"
      fill="#336ee6"
    />
    <path
      d="M13.625 19.125C15.487 19.125 17 20.639 17 22.5C17 24.361 15.487 25.875 13.625 25.875C11.764 25.875 10.25 24.361 10.25 22.5C10.25 20.639 11.764 19.125 13.625 19.125ZM22.375 19.125C24.237 19.125 25.75 20.639 25.75 22.5C25.75 24.361 24.237 25.875 22.375 25.875C20.514 25.875 19 24.361 19 22.5C19 20.639 20.514 19.125 22.375 19.125ZM13.625 10.125C15.487 10.125 17 11.639 17 13.5C17 15.361 15.487 16.875 13.625 16.875C11.764 16.875 10.25 15.361 10.25 13.5C10.25 11.639 11.764 10.125 13.625 10.125ZM22.375 10.125C24.237 10.125 25.75 11.639 25.75 13.5C25.75 15.361 24.237 16.875 22.375 16.875C20.514 16.875 19 15.361 19 13.5C19 11.639 20.514 10.125 22.375 10.125Z"
      fill="white"
    />
  </svg>
)

export default AppsIcon
