const NotificationsIcon = ({ color = '#d05215' }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.14401C27.13 5.52161 24.7739 4.60121 22.1738 4.25691C19.6907 3.92781 18 4.00881 18 4.00881C18 4.00881 16.309 3.92781 13.8258 4.25691C11.2258 4.60121 8.86979 5.52161 7.14429 7.14401C5.52149 8.86981 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.86981 28.856 7.14401Z"
      fill={color}
    />
    <path
      d="M20.1891 12.6655C20.1603 12.7902 20.1355 12.915 20.1154 13.0438C20.0963 13.1685 20.0799 13.2943 20.0704 13.421C20.0627 13.5388 20.054 13.6545 20.054 13.7732C20.054 16.2757 21.9884 18.3111 24.3657 18.3111C24.3734 18.3111 24.3791 18.3091 24.3859 18.3091C24.5074 18.3091 24.628 18.3011 24.7458 18.2901C24.8681 18.2789 24.9869 18.2599 25.1055 18.2387V19.5971C25.1055 21.4746 23.6537 23 21.8688 23H13.2368C11.4528 23 10 21.4746 10 19.5971V16.0684C10 14.1918 11.4528 12.6655 13.2368 12.6655H20.1891ZM24.3661 11C25.8218 11 27 12.2416 27 13.7731C27 15.0338 26.2008 16.0963 25.105 16.4334C24.9891 16.4686 24.8687 16.4948 24.746 16.5139C24.6284 16.5321 24.5087 16.5441 24.3862 16.5451L24.3661 16.5461C22.9114 16.5461 21.7322 15.3045 21.7322 13.7731C21.7322 13.6533 21.7427 13.5376 21.7562 13.4218C21.7705 13.293 21.7954 13.1673 21.8271 13.0435C21.8614 12.9158 21.9016 12.791 21.9505 12.6703C22.3552 11.6882 23.2836 11 24.3661 11Z"
      fill="white"
    />
  </svg>
)

export default NotificationsIcon
