const DevIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.14401C27.13 5.52161 24.7739 4.60121 22.1738 4.25691C19.6907 3.92781 18 4.00881 18 4.00881C18 4.00881 16.309 3.92781 13.8258 4.25691C11.2258 4.60121 8.86979 5.52161 7.14429 7.14401C5.52149 8.86981 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.86981 28.856 7.14401Z"
      fill="#5e5e92"
    />
    <path
      d="M14.5002 24.8746H13.5002C12.8102 24.8746 12.2502 24.3613 12.2502 23.7288V12.958C12.2502 12.3255 12.8102 11.8121 13.5002 11.8121H14.5002"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M21.3167 24.8746H22.3167C23.0067 24.8746 23.5667 24.3613 23.5667 23.7288V12.958C23.5667 12.3255 23.0067 11.8121 22.3167 11.8121H21.3167"
      stroke="white"
      strokeWidth="1.5"
    />
  </svg>
)

export default DevIcon
