const SpecialIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.14401C27.13 5.52161 24.7739 4.60121 22.1738 4.25691C19.6907 3.92781 18 4.00881 18 4.00881C18 4.00881 16.309 3.92781 13.8258 4.25691C11.2258 4.60121 8.86979 5.52161 7.14429 7.14401C5.52149 8.86981 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.86981 28.856 7.14401Z"
      fill="#3d9d32"
    />
    <path
      d="M24.849 14.4981H11.152C10.516 14.4981 10 15.0121 10 15.6481C10 16.2841 10.516 16.7991 11.152 16.7991L14.903 17.0921C15.015 17.1001 15.11 17.1781 15.138 17.2851C15.287 17.8661 15.363 18.2521 15.363 18.8541C15.363 19.1081 15.328 19.3621 15.261 19.6061L13.33 26.3831C13.165 26.9971 13.531 27.6271 14.144 27.7931C14.76 27.9581 15.391 27.5941 15.555 26.9801L17.689 21.6341H18.065L20.199 26.9801C20.363 27.5941 20.995 27.9581 21.61 27.7931C22.223 27.6271 22.588 26.9971 22.423 26.3831L20.493 19.6061C20.425 19.3621 20.391 19.1081 20.391 18.8541C20.39 18.2521 20.466 17.8661 20.617 17.2851C20.643 17.1781 20.739 17.1001 20.85 17.0921L24.849 16.7991C25.485 16.7991 26 16.2841 26 15.6481C26 15.0121 25.485 14.4981 24.849 14.4981ZM19.8559 10.8003C19.8559 9.71434 18.9689 8.83334 17.8769 8.83334C16.7849 8.83334 15.8989 9.71434 15.8989 10.8003C15.8989 11.8863 16.7849 12.7683 17.8769 12.7683C18.9689 12.7683 19.8559 11.8863 19.8559 10.8003Z"
      fill="white"
    />
  </svg>
)

export default SpecialIcon
