const ExtraIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M28.856 7.14401C27.13 5.52161 24.7739 4.60121 22.1738 4.25691C19.6907 3.92781 18 4.00881 18 4.00881C18 4.00881 16.309 3.92781 13.8258 4.25691C11.2258 4.60121 8.86979 5.52161 7.14429 7.14401C5.52149 8.86981 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.86981 28.856 7.14401Z"
        fill="#c53831"
      />
      <path
        d="M17.3658 18.1501V14.6043C17.3658 14.2075 17.6877 13.8855 18.0845 13.8855C18.4813 13.8855 18.8033 14.2075 18.8033 14.6043V18.1501C18.8033 18.5469 18.4813 18.8689 18.0845 18.8689C17.6877 18.8689 17.3658 18.5469 17.3658 18.1501ZM18.0835 20.2939C18.6135 20.2939 19.0428 20.7232 19.0428 21.2522C19.0428 21.7822 18.6135 22.2106 18.0835 22.2106C17.5545 22.2106 17.1262 21.7822 17.1262 21.2522C17.1262 20.7232 17.5545 20.2939 18.0835 20.2939ZM25.4502 23.5781H24.261L22.9998 13.0096C22.8666 11.9008 21.8527 11 20.7372 11H15.4328C14.3173 11 13.3024 11.9008 13.1702 13.0096L11.908 23.5781H10.7188C10.322 23.5781 10 23.9001 10 24.2969C10 24.6936 10.322 25.0156 10.7188 25.0156H25.4502C25.847 25.0156 26.169 24.6936 26.169 24.2969C26.169 23.9001 25.847 23.5781 25.4502 23.5781Z"
        fill="white"
      />
    </g>
  </svg>
)

export default ExtraIcon
