const WallpappersIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.14401C27.13 5.52161 24.7739 4.60121 22.1738 4.25691C19.6907 3.92781 18 4.00881 18 4.00881C18 4.00881 16.309 3.92781 13.8258 4.25691C11.2258 4.60121 8.86979 5.52161 7.14429 7.14401C5.52149 8.86981 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.86981 28.856 7.14401Z"
      fill="#d54f74"
    />
    <path
      d="M14.898 14.7528C14.898 14.1179 15.4337 13.6023 16.0955 13.6023C16.7563 13.6023 17.292 14.1179 17.292 14.7528C17.292 15.3877 16.7563 15.9024 16.0955 15.9024C15.4337 15.9024 14.898 15.3877 14.898 14.7528ZM22.7143 23.0021C22.7143 23.4221 22.3132 23.7647 21.8212 23.7647H14.1788C14.088 23.7647 13.2857 23.749 13.2857 23.208V20.5101L14.8997 18.947C15.0703 18.7939 15.3455 18.7955 15.5117 18.952L16.7623 20.1239C16.9295 20.2812 17.202 20.2803 17.3683 20.1239L20.5243 17.1517C20.6915 16.9953 20.9597 16.9977 21.1235 17.1576L22.7143 18.7667V23.0021ZM12 12.9987V23.208C12 24.3116 12.9755 25 14.1788 25H21.8212C23.0237 25 24 24.1057 24 23.0021V12.9987C24 11.8952 23.0237 11 21.8212 11H14.1788C12.9755 11 12 11.8952 12 12.9987Z"
      fill="white"
    />
  </svg>
)

export default WallpappersIcon
