const BlockScreenIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.14401C27.13 5.52161 24.7739 4.60121 22.1738 4.25691C19.6907 3.92781 18 4.00881 18 4.00881C18 4.00881 16.309 3.92781 13.8258 4.25691C11.2258 4.60121 8.86979 5.52161 7.14429 7.14401C5.52149 8.86981 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.86981 28.856 7.14401Z"
      fill="#137ae3"
    />
    <path
      d="M21.8585 11C23.0408 11 24 11.9076 24 13.0276V22.9714C24 24.0914 23.0408 25 21.8585 25H14.1405C12.9583 25 12 24.0914 12 22.9714V13.0276C12 11.9076 12.9583 11 14.1405 11H21.8585ZM17.9999 13.5057C16.8235 13.5057 15.8669 14.4728 15.8669 15.6613V16.5991H15.7726C15.1932 16.5991 14.72 17.0696 14.72 17.6439V20.5082C14.72 21.0825 15.1932 21.553 15.7726 21.553H20.2265C20.8058 21.553 21.2791 21.0825 21.2791 20.5082V17.6439C21.2791 17.0696 20.8058 16.5991 20.2265 16.5991H20.1322V15.6613C20.1322 14.4728 19.1755 13.5057 17.9999 13.5057ZM17.9998 14.5509C18.5951 14.5509 19.0793 15.0489 19.0793 15.6617V16.5994H16.9201V15.6617C16.9201 15.0489 17.4044 14.5509 17.9998 14.5509Z"
      fill="white"
    />
  </svg>
)

export default BlockScreenIcon
