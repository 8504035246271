const ConnectedDevicesIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.14401C27.13 5.52161 24.7739 4.60121 22.1738 4.25691C19.6907 3.92781 18 4.00881 18 4.00881C18 4.00881 16.309 3.92781 13.8258 4.25691C11.2258 4.60121 8.86979 5.52161 7.14429 7.14401C5.52149 8.86981 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.86981 28.856 7.14401Z"
      fill="#336ee6"
    />
    <path
      d="M21.9554 20.1972C21.1889 21.6198 19.7029 22.5867 17.9996 22.5867C16.2964 22.5867 14.8111 21.6198 14.0446 20.1972C13.6924 19.5445 13.492 18.7953 13.492 17.9999C13.492 17.2038 13.6924 16.4546 14.0446 15.8027C14.8111 14.3801 16.2964 13.4133 17.9996 13.4133C19.7029 13.4133 21.1889 14.3801 21.9554 15.8027C22.3076 16.4546 22.5072 17.2038 22.5072 17.9999C22.5072 18.7953 22.3076 19.5445 21.9554 20.1972ZM22.4443 13.9005C22.2724 13.7077 22.1759 13.456 22.1759 13.1949V11.6836C22.1759 10.7543 21.4346 10 20.5205 10H15.4795C14.5654 10 13.8249 10.7543 13.8249 11.6836V13.1949C13.8249 13.456 13.7276 13.7077 13.5557 13.9005C12.5896 14.9843 12 16.4213 12 17.9999C12 19.5777 12.5896 21.0156 13.5557 22.0994C13.7276 22.2931 13.8249 22.5439 13.8249 22.8051V24.3155C13.8249 25.2455 14.5654 26 15.4795 26H20.5205C21.4346 26 22.1759 25.2455 22.1759 24.3155V22.8051C22.1759 22.5439 22.2724 22.2931 22.4443 22.0994C23.4104 21.0156 24 19.5777 24 17.9999C24 16.4213 23.4104 14.9843 22.4443 13.9005Z"
      fill="white"
    />
  </svg>
)

export default ConnectedDevicesIcon
