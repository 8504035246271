const BateryIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.144C27.13 5.5216 24.7739 4.6012 22.1738 4.2569C19.6907 3.9278 18 4.0088 18 4.0088C18 4.0088 16.309 3.9278 13.8258 4.2569C11.2258 4.6012 8.86979 5.5216 7.14429 7.144C5.52149 8.8698 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.8698 28.856 7.144Z"
      fill="#0f9c8c"
    />
    <path
      d="M21.3673 11.6608H20.284V10.8469C20.284 10.3785 19.9192 10 19.4677 10H16.6106C16.1599 10 15.7942 10.3785 15.7942 10.8469V11.6608H14.6327C13.7313 11.6608 13 12.4196 13 13.3547V22.8827C13 24.0523 13.9143 25 15.0408 25H20.9592C22.0865 25 23 24.0523 23 22.8827V13.3547C23 12.4196 22.2693 11.6608 21.3673 11.6608Z"
      fill="white"
    />
  </svg>
)

export default BateryIcon
