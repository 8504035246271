const DocumentationIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.14401C27.13 5.52161 24.7739 4.60121 22.1738 4.25691C19.6907 3.92781 18 4.00881 18 4.00881C18 4.00881 16.309 3.92781 13.8258 4.25691C11.2258 4.60121 8.86979 5.52161 7.14429 7.14401C5.52149 8.86981 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.86981 28.856 7.14401Z"
      fill="#e2a42d"
    />
    <path
      d="M23.6959 15.4763C23.6959 17.7671 22.3417 19.7413 20.39 20.6463V22.1621H15.61V20.6463C13.6592 19.7421 12.3042 17.7679 12.3042 15.4763C12.3042 12.3313 14.855 9.78043 18 9.78043C21.145 9.78043 23.6959 12.3313 23.6959 15.4763ZM15.61 24.1546H15.6109V23.5613H20.3909V24.1546C20.3909 25.0913 19.7759 25.1288 19.3892 25.2504C19.1809 25.8163 18.6392 26.2196 18.0009 26.2196C17.3625 26.2196 16.8209 25.8154 16.6125 25.2504C16.225 25.1288 15.61 25.0921 15.61 24.1546Z"
      fill="white"
    />
  </svg>
)

export default DocumentationIcon
