const LocationIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.856 7.14401C27.13 5.52161 24.7739 4.60121 22.1738 4.25691C19.6907 3.92781 18 4.00881 18 4.00881C18 4.00881 16.309 3.92781 13.8258 4.25691C11.2258 4.60121 8.86979 5.52161 7.14429 7.14401C5.52149 8.86981 4.60119 11.2259 4.25669 13.8259C3.92759 16.3089 4.00889 17.9999 4.00889 17.9999C4.00889 17.9999 3.92759 19.6907 4.25669 22.1739C4.60119 24.7737 5.52149 27.13 7.14409 28.8558C8.86979 30.4784 11.2258 31.3986 13.8258 31.7433C16.309 32.0722 18 31.9912 18 31.9912C18 31.9912 19.6907 32.0722 22.1738 31.7433C24.7739 31.3986 27.13 30.4784 28.8556 28.8558C30.4786 27.13 31.3988 24.7737 31.7433 22.1739C32.0722 19.6907 31.9912 17.9999 31.9912 17.9999C31.9912 17.9999 32.0722 16.3089 31.7433 13.8259C31.3988 11.2259 30.4786 8.86981 28.856 7.14401Z"
      fill="#535de3"
    />
    <path
      d="M17.9996 19.042C16.6225 19.042 15.5049 17.8447 15.5049 16.3656C15.5049 14.8902 16.6225 13.6928 17.9996 13.6928C19.3775 13.6928 20.4942 14.8902 20.4942 16.3656C20.4942 17.8447 19.3775 19.042 17.9996 19.042ZM17.9988 10C14.6909 10 12 12.885 12 16.4308C12 18.8869 13.742 20.9553 13.8102 21.0337L17.0525 25.5334C17.5505 26.1557 18.4486 26.1557 18.9467 25.5324L22.1815 21.0418C22.2563 20.9553 24 18.8869 24 16.4308C24 12.885 21.3083 10 17.9988 10Z"
      fill="white"
    />
  </svg>
)

export default LocationIcon
